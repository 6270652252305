import * as React from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title='404: Not found' />
    <Flex
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      h='75.4vh'
      mx='4'
    >
      <Heading
        fontSize={{ base: '56px', xl: '60px' }}
        textAlign='center'
        textTransform='uppercase'
        letterSpacing='0.03em'
        fontFamily='Poppins'
        fontWeight='black'
        lineHeight='109%'
        color='#303056'
      >
        404: Not Found
      </Heading>
      <Text
        fontSize={{ base: '24px', xl: '24px' }}
        textTransform='uppercase'
        textAlign='center'
        letterSpacing='0.485em'
        fontFamily='Poppins'
        fontWeight='medium'
        lineHeight='109%'
        color='white'
        mt='8'
      >
        You just hit a route that doesn&#39;t exist... the sadness.
      </Text>
    </Flex>
  </Layout>
);

export default NotFoundPage;
